<template>
  <div class="b-page">
    <div class="b-page__content">
      <h1 class="main-page">Услуги и сервис</h1>
      <div class="container block-margin-bottom">
        <div class="mini_banners">
          <div class="container block-margin">
            <div class="b-page__content">
              <div class="catalog-section" v-for="(item, index) in services" :key="index">
                <div class="js-product section-item" v-show="item.view">
                  <div class="row">
                    <div class="col-md-4 section-item__pic">
                      <router-link
                          :to="'/service/'+index"
                      >
                        <img
                            class="section-item__img"
                            :src=item.img
                        />
                      </router-link>
                    </div>
                    <div class="col-md-7">
                      <div class="section-item__name">
                        <router-link
                            :to="'/service/'+index"
                        >
                          {{ item.name }}
                        </router-link>
                      </div>
                      <div class="price clearfix">
                        <svg class="icon-svg svg-wallet">
                          <use xlink:href="#svg-wallet"></use>
                        </svg>

                        <div class="price__now">
                          Стоимость:
                          <div class="price__num">
                            <span>{{ item.startPrice }}</span>
                            руб.
                          </div>
                        </div>
                      </div>
                      <div class="section-item__button">
                        <router-link
                            class="section-item__button-buy btn btn-primary "
                            title="Записаться на сервис"
                            to="/singup"
                        >
                          Записаться
                        </router-link>
                        <router-link
                            class="section-item__button-detail btn btn-default"
                            :to="'/service/'+index"
                        >Подробнее</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myStore from '@/store/Store'
export default {
  name: "Services",

  setup() {
    const {services} = myStore()
    return {
      services
    }
  }
}
</script>

<style scoped>

</style>